<template>
  <v-container style="height: 90px; position: absolute">
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      min-height="90"
      height="90"
    >
      <v-container
        class="mx-auto py-0"
      >
        <v-row>
          <base-img
            :src="require(`@/assets/logo-2.png`)"
            contain
            max-width="200"
            max-height="74"
            width="100%"
            class="ml-sm-4"
            style="cursor: pointer"
            @click="$router.push('/')"
          />
          <v-spacer />

          <div>
            <v-tabs
              class="hidden-sm-and-down mt-4"
              optional
              background-color="transparent"
            >
              <v-tab
                v-for="(item, i) in items"
                :key="i"
                :ripple="false"
                class="font-weight-bold"
                min-width="96"
                text
                @click="moveTo(item.route)"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </div>

          <v-app-bar-nav-icon
            class="hidden-md-and-up mt-3"
            @click="drawer = !drawer"
          />
        </v-row>
      </v-container>
    </v-app-bar>
    <app-bar-drawer
      v-model="drawer"
      :menu-items="items"
    />
  </v-container>
</template>

<script>
  import appBarDrawer from './Drawer'
  export default {
    name: 'HomeAppBar',
    components: { appBarDrawer },
    data: () => ({
      drawer: false,
      items: [
        {
          title: 'Главная',
          route: 'hero',
        },
        {
          title: 'Возможности',
          route: 'pro-features2',
        },
        {
          title: 'Демо',
          route: 'demo-request',
        },
        {
          title: 'контакты',
          route: 'info-alt',
        },
      ],
    }),
    methods: {
      moveTo (id) {
        const element = document.getElementById(id)
        if (element) {
          const yOffset = -90
          const y = element.getBoundingClientRect().top + window.scrollY + yOffset
          window.scrollTo({ behavior: 'smooth', top: y })
        }
      },
    },
  }
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
